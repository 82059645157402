<script setup>
import { useSlots, ref, provide } from "vue";

const slots = useSlots();
const tabTitles = ref(slots.default().map((tab) => tab.props.title));
const selectedTitle = ref(tabTitles.value[0]);

provide("selectedTitle", selectedTitle);

const emit = defineEmits(['tab-changed']);

function changeTab(title) {
  selectedTitle.value = title;
  emit('tab-changed', title);
}
</script>

<template>
  <div class="tabs">
    <ul class="tabs__header">
      <li
        v-for="title in tabTitles"
        :key="title"
        class="tabs__item"
        :class="{ selected: selectedTitle === title }"
        @click="changeTab(title)"
      >
        {{ title }}
      </li>
    </ul>

    <slot />
  </div>
</template>

<style>
.tabs__header {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  color: #fff;
  font-weight: 600;
}

.tabs__item {
  background-color: #1d2e48;
  width: auto;
  padding: 0.8rem;
  cursor: pointer;
}

.tabs__item.selected {
  background-color: #fff;
  color: #1d2e48;
}
</style>
